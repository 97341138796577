export const AppleIcon = (): JSX.Element => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.869 7.92667C11.8621 6.69765 12.4354 5.77002 13.5959 5.08686C12.9466 4.18603 11.9657 3.6904 10.6706 3.59329C9.44447 3.49952 8.10441 4.28649 7.61397 4.28649C7.09591 4.28649 5.90781 3.62678 4.97529 3.62678C3.04809 3.65692 1 5.117 1 8.08741C1 8.9648 1.16578 9.87121 1.49734 10.8067C1.93943 12.0357 3.53507 15.0496 5.19979 14.9994C6.07014 14.9793 6.68491 14.3999 7.81775 14.3999C8.91605 14.3999 9.48592 14.9994 10.4564 14.9994C12.135 14.9759 13.5786 12.2366 14 11.0042C11.7481 9.97614 11.869 7.99029 11.869 7.92667ZM9.91419 2.4279C10.8571 1.34288 10.7707 0.354975 10.7431 0C9.91073 0.0468835 8.94713 0.549207 8.39798 1.16874C7.79357 1.83181 7.43783 2.65227 7.51382 3.57654C8.41525 3.64352 9.23725 3.19478 9.91419 2.4279Z' />
    </svg>
  )
}
export const GooglePlayIcon = (): JSX.Element => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {/* <path d='M11.7195 5.80239C10.0931 4.89274 7.48273 3.43205 3.43385 1.16499C3.16087 0.984709 2.8468 0.961084 2.57544 1.05293L9.52218 7.99968L11.7195 5.80239Z' fill='currentColor' /> */}
      <path d='M2.57551 1.05322C2.52465 1.07045 2.47516 1.09123 2.42782 1.11633C2.12816 1.27843 1.90796 1.59034 1.90796 1.98483V14.0151C1.90796 14.4096 2.12813 14.7215 2.42782 14.8836C2.47507 14.9087 2.52454 14.9295 2.57534 14.9468L9.52225 7.99997L2.57551 1.05322Z' fill='currentColor' />
      {/* <path d='M9.52235 7.99951L2.57544 14.9464C2.84688 15.0389 3.16096 15.0171 3.43401 14.8342C7.36056 12.6355 9.9415 11.1921 11.5867 10.2747C11.6325 10.249 11.6774 10.2238 11.7217 10.1989L9.52235 7.99951Z' fill='currentColor' /> */}
      <path d='M1.90796 7.99951V14.0146C1.90796 14.4091 2.12813 14.7211 2.42782 14.8831C2.47507 14.9082 2.52454 14.9291 2.57534 14.9464L9.52225 7.99951H1.90796Z' fill='currentColor' />
      <path d='M3.43383 1.16483C3.10967 0.950778 2.7274 0.956876 2.42773 1.11591L9.41673 8.10493L11.7194 5.80223C10.0931 4.89258 7.48271 3.43189 3.43383 1.16483Z' fill='currentColor' fill-opacity='0.5' />
      <path d='M9.41678 7.89404L2.42773 14.883C2.72742 15.042 3.10967 15.0512 3.43383 14.8341C7.36037 12.6354 9.94132 11.192 11.5865 10.2746C11.6323 10.2489 11.6772 10.2237 11.7215 10.1988L9.41678 7.89404Z' fill='currentColor' fill-opacity='0.5' />
      <path d='M14.6203 7.99948C14.6203 7.66615 14.4521 7.32977 14.1187 7.14323C14.1187 7.14323 13.4953 6.79539 11.5844 5.72656L9.31152 7.99948L11.5867 10.2746C13.4766 9.2135 14.1187 8.8557 14.1187 8.8557C14.4521 8.66919 14.6203 8.3328 14.6203 7.99948Z' fill='currentColor' fill-opacity='0.25' />
      {/* <path d='M14.1187 8.85576C14.4521 8.66922 14.6203 8.33284 14.6203 7.99951H9.31152L11.5867 10.2747C13.4766 9.21356 14.1187 8.85576 14.1187 8.85576Z' fill='currentColor' style={{ filter: 'opacity(50%)' }} /> */}
    </svg>

  )
}

export const ArrowRightIcon = (): JSX.Element => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='currentColor' stroke='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.5 8L8.5 3M13.5 8L8.5 13M13.5 8H3.5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}
