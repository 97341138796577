import React from 'react'
import style from './style.module.css'
import { Header } from '@root/components/atoms/Header/Component'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Footer } from '@root/components/atoms/Footer/Component'
import { AppButtonType } from '@root/customizables/constants'
import { Button } from '@root/components/atoms/Button/Component'
import { hasValue } from '@root/lib/helpers'
import { customizableData as data } from '@customizables/data'
import { getIcon } from '@root/components/atoms/IconButton/Icon'
import InternalOrExternalLink from '@root/lib/link/Component'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'
import useWindowSize from '@root/lib/hooks/use-window-size'

export const NotFoundTemplate = (): JSX.Element => {
  useMetaTags({ ...data.home.seo, ...{ pageTitle: `Pagina niet gevonden - ${data.general.companyName}` }, ...data.general.metatags })

  const windowSize = useWindowSize()

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {windowSize.isTablet && (
          <Header />
        )}
        <SocialMedia isPage />
      </div>

      <Grid className={style.content}>
        <div>
          <div>
            <h2>Deze pagina bestaat niet</h2>
            <p className='larger'>Mogelijk is de link onjuist, is de pagina inmiddels verwijderd of is deze verplaatst.</p>

            <Button
              iconPosition='right'
              wrapperClassName={style.button}
              as='link'
              buttonType={AppButtonType.ArrowRight}
              href='/'
            >
              Ga naar home
            </Button>

            {hasValue(data.general.globalEmail) && (
              <p className={style.email}>
                {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
              </p>
            )}
          </div>
        </div>
      </Grid>

      <div className={style.wrapper}>
        <Footer />
      </div>
    </div>
  )
}
