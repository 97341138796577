import { AppButtonType, ButtonStyleType, ButtonType } from '@customizables/constants'
import InternalOrExternalLink from '@root/lib/link/Component'
import clsx from 'clsx'
import React from 'react'
import { AppleIcon, GooglePlayIcon, ArrowRightIcon } from './Icon'
import style from './style.module.css'
import { motion } from 'framer-motion'
import { customizableData as data } from '@customizables/data'

interface ButtonProps {
  children: React.ReactNode
  buttonType: AppButtonType
  wrapperClassName?: string
  iconPosition?: 'left' | 'right'
}

type Props =
  | ({ as: 'link' } & ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ as: 'button' } & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>)

export const Button = (props: Props): JSX.Element => {
  const { children, ...componentProps } = props
  const [active, setActive] = React.useState<boolean>(false)
  const [hover, setHover] = React.useState<boolean>(false)

  const classes = clsx(
    componentProps.className,
    style.button,
    props.iconPosition === 'right' && style.reverse,
    data.general.button.buttonStyle === ButtonStyleType.Gradient && style.gradient,
    data.general.button.buttonVariant === ButtonType.Circle && style.circle,
    'button'
  )
  const returnIcon = (btnType: AppButtonType): JSX.Element => {
    switch (btnType) {
      case AppButtonType.ArrowRight:
        return <ArrowRightIcon />
      case AppButtonType.Apple:
        return <AppleIcon />
      case AppButtonType.GooglePlay:
      default:
        return <GooglePlayIcon />
    }
  }

  const standardScale = 1
  const activeScale = 0.95
  const hoverScale = 1.05

  if (componentProps.as === 'link') {
    const { as, className, wrapperClassName, buttonType, ...linkProps } = componentProps
    return (

      <motion.div
        initial={{ scale: standardScale }}
        animate={{ scale: active ? activeScale : hover ? hoverScale : standardScale }}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          setHover(false)
          setActive(false)
        }}
        className={wrapperClassName}
      >
        <InternalOrExternalLink
          className={classes}
          {...linkProps}
        >
          <span className={style.icon}>{returnIcon(buttonType)}</span> <span>{children}</span>
        </InternalOrExternalLink>
      </motion.div>

    )
  } else {
    const { as, className, wrapperClassName, buttonType, ...buttonProps } = componentProps
    return (
      <motion.div
        initial={{ scale: standardScale }}
        animate={{ scale: active ? activeScale : hover ? hoverScale : standardScale }}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          setHover(false)
          setActive(false)
        }}
        className={wrapperClassName}
      >
        <button
          className={classes}
          {...buttonProps}
        >
          <span className={style.icon}>{returnIcon(buttonType)}</span> <span>{children}</span>
        </button>
      </motion.div>
    )
  }
}
