import React from 'react'
import { LeftColumnHome } from '../../organisms/LeftColumnHome/Component'
import { RightColumnHome } from '../../organisms/RightColumnHome/Component'
import style from './style.module.css'
import { customizableData as data } from '@customizables/data'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'

export const HomeTemplate = (): JSX.Element => {
  useMetaTags({ ...data.home.seo, ...data.general.metatags })

  return (
    <div className={style.wrapper}>
      <div>
        <LeftColumnHome />
      </div>
      <div>
        <RightColumnHome />
      </div>
    </div>
  )
}
