import React from 'react'
import ReactDOM from 'react-dom/client'
import '@customizables/fonts.css'
import '@customizables/variables.css'
import '@customizables/typography.css'
import '@styles/main.css'
import Router from './Router'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
)

reportWebVitals()
