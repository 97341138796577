import React, { CSSProperties } from 'react'
import { BackgroundType } from '@customizables/constants'
import { customizableData as data } from '@customizables/data'
import clsx from 'clsx'
import style from './style.module.css'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Image } from '@root/components/atoms/Image/Component'
import { hasValue } from '@root/lib/helpers'
import ImagePng from '@customizables/images/original/image.png'

export const RightColumnHome = (): JSX.Element => {
  const returnClass = (type: BackgroundType): string => {
    switch (type) {
      case BackgroundType.Image:
        return 'image'
      case BackgroundType.Gradient:
        return 'gradient'
      case BackgroundType.Solid:
      default:
        return 'solid'
    }
  }

  const getStyle = (): CSSProperties => {
    if (data.home.backgroundType === BackgroundType.Image && hasValue(data.home.backgroundImageFile)) {
      // const fileChunks = data.home.backgroundImageFile.split('.')
      // const imageSrcs = [
      //   `/images/customizables/${fileChunks[0]}@1x.${fileChunks[1]}`,
      //   `/images/customizables/${fileChunks[0]}@2x.${fileChunks[1]}`,
      //   `/images/customizables/${fileChunks[0]}@3x.${fileChunks[1]}`
      // ]
      // return {
      //   backgroundImage: `image-set(
      //     url("${imageSrcs[0]}") 1x,
      //     url("${imageSrcs[1]}") 2x,
      //     url("${imageSrcs[2]}") 3x)`
      // }

      return {
        backgroundImage: `url(${data.home.backgroundImageFile})`
      }
    } else {
      return {}
    }
  }

  const customStyle = getStyle()

  return (
    <div
      className={
        clsx(
          style.wrapper,
          style[returnClass(data.home.backgroundType)]
        )
      }
      style={customStyle}
    >
      <SocialMedia />
      <div className={style.container}>
        <div>
          <Image url={ImagePng} alt={data.home.image.alt} />
        </div>
      </div>
    </div>
  )
}
