import React from 'react'
import { customizableData as data } from '@customizables/data'
import style from './style.module.css'
import InternalOrExternalLink from '@root/lib/link/Component'
import clsx from 'clsx'

interface Props {
  className?: string
}

export const Footer = (props: Props): JSX.Element => {
  return (
    <footer className={clsx(style.footer, props.className)}>
      <p>© {data.general.companyName}, {new Date().getFullYear()}</p>
      <ul>
        {
          data.general.serviceMenu.map(link => {
            const { label, ...linkProps } = link
            return <li key={link.href}> <InternalOrExternalLink {...linkProps}>{label}</InternalOrExternalLink></li>
          })

        }
      </ul>
    </footer>
  )
}
