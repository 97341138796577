import InternalOrExternalLink from '@root/lib/link/Component'
import React from 'react'
import { motion } from 'framer-motion'
import style from './style.module.css'
import { getIcon } from './Icon'
import clsx from 'clsx'
import { hasValue } from '@root/lib/helpers'

interface Props {
  icon: string
  values: any
  isPage?: boolean
}

export const IconButton = (props: Props): JSX.Element => {
  const [active, setActive] = React.useState<boolean>(false)
  const [hover, setHover] = React.useState<boolean>(false)

  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: active ? 0.8 : hover ? 1.1 : 1 }}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false)
        setActive(false)
      }}
    >
      <InternalOrExternalLink {...props.values} className={clsx(style['icon-button'], hasValue(props.isPage) && style['icon-button-detailpage'])}>
        {getIcon(props.icon)}
      </InternalOrExternalLink>
    </motion.div>
  )
}
