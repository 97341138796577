import React from 'react'
import ReactMarkdown from 'react-markdown'
import { customizableData as data } from '@customizables/data'
import style from './style.module.css'
import { Button } from '@root/components/atoms/Button/Component'
import InternalOrExternalLink from '@root/lib/link/Component'
import { Header } from '@root/components/atoms/Header/Component'
import { Footer } from '@root/components/atoms/Footer/Component'
import useWindowSize from '@root/lib/hooks/use-window-size'

export const LeftColumnHome = (): JSX.Element => {
  const windowSize = useWindowSize()

  return (
    <div className={style.wrapper}>

      {windowSize.isTablet && (<Header />)}
      <div className={style['content-wrapper']}>
        <div className={style.container}>
          <div className={style.heading}>
            <h1>
              {data.home.title}
            </h1>
            <div role='doc-subtitle'>
              <p className='subtitle'>{data.home.subtitle}</p>
            </div>
          </div>

          <ReactMarkdown
            components={{
              a ({ node, ...props }) {
                return <InternalOrExternalLink {...props} />
              },
              p ({ node, ...props }) {
                return <p className='larger' {...props} />
              }
            }}

          >
            {data.home.description}
          </ReactMarkdown>

          <div className={style.buttons}>
            {
              data.home.buttons.map(button => {
                return (
                  <div key={button.title}>
                    <Button
                      as='link'
                      href={button.url}
                      buttonType={button.type}
                      wrapperClassName={style['button-wrapper']}
                    >
                      {button.title}
                    </Button>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
      <Footer className={style.footer} />
    </div>
  )
}
