import React from 'react'
import { customizableData as data } from '@customizables/data'
import style from './style.module.css'
import { IconButton } from '../IconButton/Component'
import clsx from 'clsx'
import { hasValue } from '@root/lib/helpers'
import { SocialMediaType } from '@root/customizables/constants'
import useWindowSize from '@root/lib/hooks/use-window-size'
import { Header } from '../Header/Component'

interface Props {
  isPage?: boolean
}

export const SocialMedia = (props: Props): JSX.Element => {
  const windowSize = useWindowSize()

  return (
    <>
      <div className={clsx(style['social-media'], hasValue(props.isPage) && style['social-media-page'])}>
        {!windowSize.isTablet && (<Header />)}
        <ul>
          {
            data.general.socialMedia.slice(0, 6).map(socialMedia => {
              if (socialMedia.type === SocialMediaType.Mail) {
                return (
                  <li key={(socialMedia.type as string).toLowerCase()}>
                    <IconButton isPage={props.isPage} icon={(socialMedia.type as string).toLowerCase()} values={{ ...socialMedia, href: `mailto:${data.general.globalEmail}` }} />
                  </li>
                )
              }
              if (socialMedia.type === SocialMediaType.Telephone) {
                return (
                  <li key={(socialMedia.type as string).toLowerCase()}>
                    <IconButton isPage={props.isPage} icon={(socialMedia.type as string).toLowerCase()} values={{ ...socialMedia, href: `tel:${socialMedia.href ?? ''}` }} />
                  </li>
                )
              }

              return (
                <li key={(socialMedia.type as string).toLowerCase()}>
                  <IconButton isPage={props.isPage} icon={(socialMedia.type as string).toLowerCase()} values={socialMedia} />
                </li>
              )
            })
          }
        </ul>
      </div>
    </>
  )
}
