import React from 'react'

interface Props {
  url: string
  alt: string
}

export const Image = (props: Props): JSX.Element => {
  return (
    <img src={props.url} alt={props.alt} />
  )
}

// export const Image = (props: Props): JSX.Element => {
//   const fileChunks = props.filename.split('.')
//   const imageSrcs = [
//     `/images/customizables/${fileChunks[0]}@1x.${fileChunks[1]}`,
//     `/images/customizables/${fileChunks[0]}@2x.${fileChunks[1]}`,
//     `/images/customizables/${fileChunks[0]}@3x.${fileChunks[1]}`
//   ]
//   return (
//     <picture>
//       <source srcSet={`${imageSrcs[0]} 1x, ${imageSrcs[1]} 2x,${imageSrcs[2]} 3x`} />
//       <img src={imageSrcs[0]} alt={props.alt} />
//     </picture>
//   )
// }
