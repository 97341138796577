import { ISEO, GlobalMetaTags } from '@root/customizables/constants'
import React from 'react'
import { hasValue } from '../helpers'

type Props = GlobalMetaTags & ISEO

const createOrUpdateMetaTag = (query: string, element: 'meta' | 'link', parameter: string, property: string, value: string): void => {
  const tag = document.querySelector(query)
  if (element === 'meta') {
    if (hasValue(tag)) {
      (tag as HTMLMetaElement).content = value
    } else {
      const link = document.createElement(element)
      link.setAttribute(parameter, property)
      link.content = value
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  } else if (element === 'link') {
    if (hasValue(tag)) {
      (tag as HTMLLinkElement).href = value
    } else {
      const link = document.createElement(element)
      link.setAttribute(parameter, property)
      link.href = value
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }
}

export const useMetaTags = (props: Props | null): void => {
  React.useEffect(() => {
    if (hasValue(props)) {
      document.title = props.pageTitle
      document.documentElement.lang = props.locale

      const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--page-background-color')

      createOrUpdateMetaTag('meta[name="theme-color"]', 'meta', 'name', 'theme-color', backgroundColor)

      createOrUpdateMetaTag('meta[name="description"]', 'meta', 'name', 'description', props.pageDesc)

      createOrUpdateMetaTag('link[rel="canonical"]', 'link', 'rel', 'canonical', document.location.toString().split('?')[0])
      createOrUpdateMetaTag('meta[property="og:url"]', 'meta', 'property', 'og:url', document.location.toString().split('?')[0])

      createOrUpdateMetaTag('meta[property="og:title"]', 'meta', 'property', 'og:title', props.pageTitle)
      createOrUpdateMetaTag('meta[property="og:description"]', 'meta', 'property', 'og:description', props.pageDesc)

      createOrUpdateMetaTag('meta[property="og:image"]', 'meta', 'property', 'og:image', `${window.location.origin ?? ''}/images/share-image.jpg`)
      createOrUpdateMetaTag('meta[property="og:image:width"]', 'meta', 'property', 'og:image:width', '1200')
      createOrUpdateMetaTag('meta[property="og:image:height"]', 'meta', 'property', 'og:image:height', '627')

      createOrUpdateMetaTag('meta[property="og:type"]', 'meta', 'property', 'og:type', 'website')
      createOrUpdateMetaTag('meta[property="og:site_name"]', 'meta', 'property', 'og:site_name', props.siteTitle)
      createOrUpdateMetaTag('meta[property="og:locale"]', 'meta', 'property', 'og:locale', props.locale)

      createOrUpdateMetaTag('meta[name="twitter:card"]', 'meta', 'name', 'twitter:card', 'summary')

      createOrUpdateMetaTag('meta[name="twitter:title"]', 'meta', 'name', 'twitter:title', props.pageTitle)
      createOrUpdateMetaTag('meta[name="twitter:description"]', 'meta', 'name', 'twitter:description', props.pageDesc)

      createOrUpdateMetaTag('meta[name="twitter:image"]', 'meta', 'name', 'twitter:image', `${window.location.origin ?? ''}/images/share-image.jpg`)

      createOrUpdateMetaTag('meta[name="robots"]', 'meta', 'name', 'robots', props.robots)
    }
  }, [])
}
